import React from "react";
import mainLogo from "../Images/mainlogo.jpeg";
import styled from "styled-components";
import instagram from "../Images/instagram.png";
import facebook from "../Images/facebook.png";
import linkden from "../Images/linkedin.png";

const StyledFooter = styled.footer`
  display: flex;
  border-top: 2px solid black;
  padding: 10px;
  margin-top: 100px;
  font-style: "Poppins";
  flex-direction: column;
`;
const StyledImage = styled.img`
  width: 200px;
  height: 70px;
  background-size: cover;
`;
const StyledImg = styled.img`
  width: 30px;
`;
const MyStyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  margin-top: 10px;
`;
const StyledDivMainContent = styled.div`
  display: flex;
  @media (max-width: 989px) {
    flex-direction: column;
  }
`;
const Footer = () => {
  return (
    <StyledFooter>
      <StyledDivMainContent>
        <MyStyledDiv className="width-30">
          <StyledImage src={mainLogo} alt="Main Logo" className="mainlogo" />
        </MyStyledDiv>

        <MyStyledDiv>
          <div>
            <p>
              <b>
                Telephone No: <a href="tel:+918081014114">8081014114</a>
              </b>
            </p>
            <p>
              <b>
                WhatsApp No: <a href="tel:+919026610438">9026610438</a>
              </b>
            </p>
            <p>
              <b>
                Email:{" "}
                <a href="mailto:atmohivebiotech@gmail.com">
                  atmohivebiotech@gmail.com
                </a>
              </b>
            </p>
          </div>
        </MyStyledDiv>

        <MyStyledDiv>
          <div>
            <div>
              <b>Address:</b>
            </div>
            <div>
              <b>I-126, Department of Biochemical Engineering,</b>
            </div>
            <div>
              <b>School of Chemical Technology,</b>
            </div>
            <div>
              <b>Harcourt Butler Technical University,</b>
            </div>
            <div>
              <b>Kanpur (U.P.) 208002</b>
            </div>
          </div>
        </MyStyledDiv>
      </StyledDivMainContent>
      <div
        style={{
          display: "flex",
          gap: "10px",
          justifyContent: "end",
          padding: "20px",
          marginTop: "60px",
        }}
      >
        <div>
          <a
            href="https://www.instagram.com/atmohive_biotech?igsh=eTVhbWpqemxoazB0&utm_source=qr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledImg src={instagram} alt="" />
          </a>
        </div>
        <div>
          <a
            href="https://www.facebook.com/share/eTm8849TUcVMtTiR/?mibextid=LQQJ4d"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledImg src={facebook} alt="" />
          </a>
        </div>
        <div>
          <a
            href="https://www.linkedin.com/in/atmohive-biotech-6a146a320?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledImg src={linkden} alt="" />
          </a>
        </div>
      </div>
      <div style={{ textAlign: "center", margin: "10px 0px 10px 0px" }}>
        <b>© 2024 Atmohive Biotech Pvt. Ltd. All Rights Reserved.</b>{" "}
      </div>
    </StyledFooter>
  );
};

export default Footer;
