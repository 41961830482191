import React, { useReducer, useState } from "react";
import NavbarComp from "../component/NavbarComp";
import mainLogo from "../Images/mainlogo.jpeg";
import styled from "styled-components";
import copy from "../Images/copy.png";
import useRefHook from "./useRefHook";
import TextCopied from "./TextCopied";
import { useToast } from "./useToast";
import { usePostApi } from "../Api/usePostApi";
import QRCode from "../Images/QR_code.jpeg";

const StyledImg = styled.img`
  width: 30%;
  height: 150px;
  @media (max-width: 850px) {
    width: 100%;
  }
`;
const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
`;
const StyledSpan = styled.span`
  display: flex;
  justify-content: center;
  font-size: 30px;
  font-family: Poppins;
  padding: 5px;
  background-color: lightgreen;
  margin-top: 20px;
  @media (max-width: 850px) {
    font-size: 20px;
  }
`;
const StyledDivForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 850px) {
    padding: 5px;
  }
`;
const StyledButtonDiv = styled.div`
  padding-bottom: 30px;
  @media (max-width: 850px) {
    display: flex;
    justify-content: center;
  }
`;
const StyledDivQR = styled.div`
  //style={{ display: "flex", width: "100px", height: "400px" }}
  display: flex;
  width: 100px;
  height: 400px;
  @media (max-width: 690px) {
    display: none;
  }
`;
const StyledImgCopy = styled.img`
  width: 20px;
  margin-left: 10px;
  cursor: pointer;
`;
const StyledSpanStar = styled.span`
  color: red;
`;
const reducer = (state, action) => {
  switch (action.type) {
    case "Input":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};
const initialState = {
  name: "",
  email: "",
  currentDesignation: "",
  universityWorkPlace: "",
  WhatsAppNumber: "",
  Module: "",
  Duration: "",
  receipt: "",
};

const RegisterNow = () => {
  const registerNowApi = usePostApi();
  const showToast = useToast();
  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log("My Module", state);
    if (
      state.Module === "Select" ||
      state.Duration === "Select" ||
      state.Module === "" ||
      state.Duration === ""
    ) {
      showToast("Please Select The DropDowns", 2500, "😅", "top-right");
    } else {
      const response = await registerNowApi(
        state,
        process.env.REACT_APP_REGISTER_NOW_API
      );
      if (response?.success) {
        showToast(response?.msg, 2500, "😅", "top-right");
      } else if (!response?.error?.success) {
        const data = response?.error?.errors?.errors;
        //console.log("Updated", data);
        showToast(data[0]?.msg, 2500, "😅", "top-right");
      }
    }
  };
  const [showAlert, setShowAlert] = useState(false);
  const upiIDref = useRefHook();
  const [state, dispatch] = useReducer(reducer, initialState);
  const handleChangeInput = (e) => {
    if (e.target.name === "Receipt") {
      const reader = new FileReader();
      const { name, files } = e.target;
      const splitReceipt = files[0].name.split(".");
      const len = splitReceipt.length;
      const fileType = splitReceipt[len - 1].toUpperCase();
      const arrayFileSupported = ["PNG", "JPEG", "JPG"];
      const fileSize = files[0].size / 1e6;
      //console.log(!arrayFileSupported.includes(fileType), fileType);
      if (!arrayFileSupported.includes(fileType)) {
        e.target.value = "";
        //console.log("I am Inside");
        //console.log(showToast);
        showToast(
          "Supported file formats are JPEG PNG JPG",
          2500,
          "😅",
          "top-right"
        );
      } else if (fileSize > 6) {
        e.target.value = "";
        showToast(
          "File size should be less than 6 Mb",
          2500,
          "😅",
          "top-right"
        );
      } else {
        reader.onloadend = () => {
          const value = reader.result;
          //console.log("profile ", reader.result);
          dispatch({ type: "Input", field: name, value });
        };
        reader.readAsDataURL(files[0]);
      }
      console.dir(files[0]);
    } else {
      const { name, value } = e.target;
      dispatch({ type: "Input", field: name, value });
    }
  };
  //console.log(state);
  const handleTextClick = (params) => {
    if (upiIDref.current) {
      navigator.clipboard
        .writeText(upiIDref.current.textContent)
        .then(() => {})
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    }
  };
  const handleCopyClick = () => {
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 1000);
  };
  return (
    <>
      <NavbarComp />
      <StyledDiv>
        <StyledImg src={mainLogo} alt="MainLogo" />
      </StyledDiv>
      <div>
        <StyledSpan>
          <b>Online Registration Form</b>
        </StyledSpan>
      </div>
      <StyledDivForm>
        <div className="form-floating mb-3 mt-5 width-70">
          <input
            type="text"
            className="form-control"
            id="floatingInput"
            placeholder="John Doe"
            name="name"
            value={state.name}
            onChange={handleChangeInput}
          />
          <label htmlFor="floatingInput">
            Name<StyledSpanStar>*</StyledSpanStar>
          </label>
        </div>
        <div className="form-floating width-70 mb-3">
          <input
            type="email"
            className="form-control"
            id="floatingPassword"
            placeholder="Email"
            name="email"
            value={state.email}
            onChange={handleChangeInput}
          />
          <label htmlFor="floatingPassword">
            Email<StyledSpanStar>*</StyledSpanStar>
          </label>
        </div>
        <div className="form-floating width-70 mb-3">
          <input
            type="text"
            className="form-control"
            id="floatingPassword"
            placeholder="Current Designation"
            name="currentDesignation"
            value={state.currentDesignation}
            onChange={handleChangeInput}
          />
          <label htmlFor="floatingPassword">
            Current Designation<StyledSpanStar>*</StyledSpanStar>
          </label>
        </div>
        <div className="form-floating width-70 mb-3">
          <input
            type="text"
            className="form-control"
            id="floatingPassword"
            placeholder="University/Workplace"
            name="universityWorkPlace"
            value={state.universityWorkPlace}
            onChange={handleChangeInput}
          />
          <label htmlFor="floatingPassword">
            University/Workplace<StyledSpanStar>*</StyledSpanStar>
          </label>
        </div>
        <div className="form-floating width-70 mb-3">
          <input
            type="mobile"
            className="form-control"
            id="floatingPassword"
            placeholder="WhatsApp Number"
            name="WhatsAppNumber"
            value={state.WhatsAppNumber}
            onChange={handleChangeInput}
          />
          <label htmlFor="floatingPassword">
            WhatsApp Number<StyledSpanStar>*</StyledSpanStar>
          </label>
        </div>
        <div className="form-floating width-70 mb-3">
          <select
            className="form-select"
            id="floatingSelect"
            aria-label="Modules/Services want to choose"
            name="Module"
            value={state.Module}
            onChange={handleChangeInput}
          >
            <option defaultValue>Select</option>
            <option value="Microbioloy(MY)">Microbioloy(MY)</option>
            <option value="Plant Biotechnology(PB)">
              Plant Biotechnology(PB)
            </option>
            <option value="Molecular Biology(MB)">Molecular Biology(MB)</option>
            <option value="Fermentation/Enzyme Technology(FT)">
              Fermentation/Enzyme Technology(FT)
            </option>
            <option value="Biochemistry(BC)">Biochemistry(BC)</option>
            <option value="Herbal Product Development(HPD)">
              Herbal Product Development(HPD)
            </option>
            <option value="Food Technology(FDT)">Food Technology(FDT)</option>
            <option value="Pharmaceutical Technology(PT)">
              Pharmaceutical Technology(PT)
            </option>
            <option value="Immunology(IM)">Immunology(IM)</option>
            <option value="Dry Lab Hands-On Training">
              Dry Lab Hands-On Training
            </option>
            <option value="Integrative Training Programs">
              Integrative Training Programs
            </option>
            <option value="Projects">Projects</option>
            <option value="Laboratory Testing">Laboratory Testing</option>
            <option value="Research Writing and Career Consultancy">
              Research Writing and Career Consultancy
            </option>
            <option value="Workshops and Conferences">
              Workshops and Conferences
            </option>
          </select>
          <label htmlFor="floatingSelect">
            Modules/Services want to choose
          </label>
        </div>
        <div className="form-floating width-70 mb-3">
          <select
            className="form-select"
            id="floatingSelect"
            aria-label="Floating label select example"
            name="Duration"
            value={state.Duration}
            onChange={handleChangeInput}
          >
            <option defaultValue>Select</option>
            <option value="15 Days">15 Days</option>
            <option value="1 Month">1 Month</option>
            <option value="45 Days">45 Days</option>
            <option value="3 Months">3 Months</option>
            <option value="6 Months">6 Months</option>
            <option value="Custom Programs">Custom Programs</option>
          </select>
          <label htmlFor="floatingSelect">
            Duration for which you want to join
          </label>
        </div>
        <div className="form-floating width-70 mb-3">
          <input
            type="file"
            className="form-control"
            id="floatingPassword"
            placeholder="Receipt/Screenshot of your Payment"
            name="receipt"
            onChange={handleChangeInput}
          />
          <label htmlFor="floatingPassword">
            Receipt/Screenshot of your Payment
          </label>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ color: "gray" }}>
            <div>
              <b>Account Number:</b>
              <p>083205003654</p>
            </div>
            <div>
              <b>Account Holder's Name:</b>
              <p>ATMOHIVE BIOTECH PRIVATE LIMITED</p>
            </div>
            <div>
              <b>Bank's Name:</b>
              <p>ICICI BANK</p>
            </div>
            <div>
              <b>Branch Name:</b>
              <p>Ashok Nagar</p>
            </div>
            <div>
              <b>IFSC Code:</b>
              <p>ICIC0000832</p>
            </div>
            <div>
              <b>UPI ID:</b>
              <p ref={upiIDref}>
                7905177754.eazypay@icici
                <span onClick={() => handleTextClick("UPI ID")}>
                  <StyledImgCopy
                    src={copy}
                    alt="copy"
                    onClick={handleCopyClick}
                  />
                </span>
                {showAlert && <TextCopied />}
              </p>
            </div>
          </div>
          <StyledDivQR>
            <img src={QRCode} alt="QR code" />
          </StyledDivQR>
        </div>
        <StyledButtonDiv>
          <button
            type="button"
            className="btn btn-success"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </StyledButtonDiv>
      </StyledDivForm>
    </>
  );
};

export default RegisterNow;
