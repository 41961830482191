import React from "react";
import micoscope from "../Images/microscope.jpeg";
import styled from "styled-components";

const StyledImg = styled.img`
  width: 100%;
  height: 60vh;
  border-radius: 20px;
  @media (max-width: 850px) {
    height: unset;
  }
`;
const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
`;
const StyledDivMain = styled.div`
  @media (max-width: 850px) {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
  }
`;

const AboutUs = () => {
  return (
    <StyledDivMain className="padding-20 font-fam display-pro-flex">
      <div className="width-70">
        <section>
          <h2>Our Mission</h2>
          <p className="text-size">
            Atmohive Biotech Pvt Ltd. is dedicated to briding the gap between
            academic knowledgwe and practicle skills in biotechnology and life
            sciences.
          </p>
        </section>
        <section>
          <h2>Mentors and Advisors</h2>
          <div>
            <div>
              <b>Mentor</b>
            </div>
            <div>
              <b>Dr. Lalit Kumar Singh</b>
            </div>
            <div>Professor</div>
            <div>Head of Department, Biochemical Engineering</div>
            <p>HBTU, Kanpur (U.P.)</p>
            <div>
              <b>Advisory</b>
            </div>
            <div>
              <b>Dr. Atul Bhargava</b>{" "}
            </div>
            <div>Associate Professor</div>
            <div>Head, Department of Botany</div>
            <div>Mahatma Gandhi Central University</div>
            <p>Motihari (Bihar)</p>
            <div>
              <b>Dr. Garima Awasthi</b>{" "}
            </div>
            <div>Assistant Professor</div>
            <div>Amity University</div>
            <p>Lucknow (U.P.)</p>
          </div>
        </section>
        <section>
          <h2>Our Facilities</h2>
          <p className="text-size">
            Diverse experiments and training, Company-sponsored laboratory, and
            Industrial-grade learning environment.
          </p>
        </section>
      </div>
      <StyledDiv>
        <StyledImg src={micoscope} alt="Microscope" />
      </StyledDiv>
    </StyledDivMain>
  );
};

export default AboutUs;
